var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"calendar_container",class:_vm.cardAttributes.classes,attrs:{"aut-calendar-container":""}},'v-card',_vm.cardAttributes,false),[_c('v-card-title',[_c('CalendarHeader',{attrs:{"attributes":_vm.attributes,"title":_vm.$refs && _vm.$refs.calendar && _vm.$refs.calendar.title,"type":_vm.calendarType,"date":_vm.date},on:{"prev":function($event){return _vm.$refs.calendar.prev()},"next":function($event){return _vm.$refs.calendar.next()},"change:type":function($event){_vm.activeType = $event},"change:date":function($event){_vm.date = $event}}})],1),_c('v-card-text',[_c('v-calendar',_vm._b({ref:"calendar",class:_vm.classes,attrs:{"aut-calendar":"","type":_vm.calendarType,"events":_vm.calendarEvents,"event-color":_vm.eventColor,"event-timed":_vm.eventTimed},on:{"click:time":_vm.emitCreate},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"px-1",staticStyle:{"height":"100%"},domProps:{"innerHTML":_vm._s(event.name)},on:{"click":function($event){$event.stopPropagation();return _vm.emitShow({ event: event, nativeEvent: $event })}}})]}},{key:"day-label",fn:function(ref){
var day = ref.day;
var date = ref.date;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isPastDate(date),"color":"black"},on:{"click":function($event){return _vm.handleDate({ date: date })}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(day)+" ")])]}}],null,true)},[_vm._v(" Click on date to create an event ")])]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-calendar',_vm.attributes,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }