<template>
  <v-card
    :class="cardAttributes.classes"
    v-bind="cardAttributes"
    aut-calendar-container
    class="calendar_container"
  >
    <v-card-title>
      <CalendarHeader
        :attributes="attributes"
        :title="$refs && $refs.calendar && $refs.calendar.title"
        :type="calendarType"
        :date="date"
        @prev="$refs.calendar.prev()"
        @next="$refs.calendar.next()"
        @change:type="activeType = $event"
        @change:date="date = $event"
      />
    </v-card-title>
    <v-card-text>
      <v-calendar
        aut-calendar
        v-model="date"
        ref="calendar"
        v-bind="attributes"
        v-bind:class="classes"
        :type="calendarType"
        :events="calendarEvents"
        :event-color="eventColor"
        :event-timed="eventTimed"
        @click:time="emitCreate"
      >
        <template v-slot:event="{ event }">
          <div
            class="px-1"
            style="height: 100%"
            v-html="event.name"
            @click.stop="emitShow({ event, nativeEvent: $event })"
          ></div>
        </template>
        <template v-slot:day-label="{ day, date }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="handleDate({ date })"
                v-on="on"
                :disabled="isPastDate(date)"
                v-bind="attrs"
                color="black"
              >
                {{ day }}
              </v-btn>
            </template>
            Click on date to create an event
          </v-tooltip>
        </template>
      </v-calendar>
    </v-card-text>
  </v-card>
</template>
<script>
import { defaultsDeep } from "lodash";
import { isToday, isFuture, parseISO } from "date-fns";

const debug = require('debug')('atman.fields.calendar')  // eslint-disable-line

export default {
  name: "CalendarView",
  components: {
    CalendarHeader: () => import("./CalendarHeader.vue"),
  },
  props: {
    events: {},
    attributes: Object,
    classes: {},
    card: {
      type: Object,
      default() {
        return {};
      },
    },
    mode: {},
  },
  data() {
    return {
      activeType: "",
      date: new Date(),
    };
  },
  computed: {
    cardAttributes() {
      return this.attributes?.card || {};
    },
    calendarEvents() {
      const events = this.events || [];

      if (!Array.isArray(events)) {
        return [];
      }
      const result = events.map((event) => {
        return defaultsDeep(
          {},
          {
            start: new Date(event.start),
            end: new Date(event.end),
          },
          event
        );
      });
      return result;
    },
    isInputMode() {
      return this.mode == "input";
    },
    calendarType() {
      return this.activeType || this.attributes?.type;
    },
  },
  methods: {
    isPastDate(date) {
      const dateValue = parseISO(date);
      let result = !(isToday(dateValue) || isFuture(dateValue));
      debug(`isPastDate`, date, result);
      return result;
    },
    eventColor(event) {
      const events = this.events || [];
      const defaultEventColor = this.attributes?.events?.color;
      return (
        events?.find((ev) => ev.name == event.name)?.color || defaultEventColor
      );
    },
    emitCreate(event) {
      if (this.isInputMode) {
        this.$emit("event:create", event);
      }
    },
    emitShow({ nativeEvent, event }) {
      const resultEvent = defaultsDeep(
        {},
        {
          start: new Date(event.start).toISOString(),
          end: new Date(event.end).toISOString(),
        },
        event
      );

      this.$emit("event:show", { nativeEvent, event: resultEvent });
    },
    handleDate(event) {
      const showDayView = this.attributes?.day_view || false;
      if (showDayView) {
        this.toggleDayView();
        return;
      }

      this.emitCreate(event);
    },
    toggleDayView() {
      if (this.activeType == "day") {
        this.activeType = "";
        return;
      }
      this.activeType = "day";
    },
    changeType(type) {
      this.activeType = type;
    },
    eventTimed(event) {
      return event?.start && event?.end;
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar_container {
  overflow-y: auto;
  ::v-deep {
    .v-present {
      button {
        background-color: transparent !important;
        color: var(--v-primary-base) !important;
      }
    }
    button {
      width: 28px !important;
      height: 28px !important;
    }
  }
}
</style>
